<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-21 17:10:45
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-21 18:37:13
-->
<template>
  <div class="sunburst">
    <div :id="id" :option="option"></div>
  </div>
</template>

<script>
import HighCharts from 'highcharts'
import sunburst from 'highcharts/modules/sunburst'
sunburst(HighCharts)

export default {
  name: 'sunburst',

  data() {
    return {
      
    }
  },

  props: {
    id: {
      type: String,
      default: ''
    },
    option: {
      type: Object,
      default: {}
    }
  },

  computed: {},

  mounted() {
    this.getCharts()
  },

  methods: {
    getCharts() {
      HighCharts.getOptions().colors.splice(0, 0, 'transparent');
      HighCharts.chart(this.id,this.option)
    }
  },

}
</script>

<style lang='scss' scoped>
</style>