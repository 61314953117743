<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-06-21 17:09:38
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-24 09:54:33
-->
<template>
  <a-card :border="false">
    <div class="sales_accounted_for">
      <SunburstStatis :id="'sales_accounted_for'" :option="charts" ref="SunburstStatis"></SunburstStatis>
    </div>
  </a-card>
</template>

<script>
import SunburstStatis from './highcharts/SunburstStatis.vue'

let data = [
  {
    parent: 'NU',
    id: 'TOP_0',
    name: '总额',
    value: null,
  },
  {
    parent: 'TOP_0',
    id: 'AREA_3',
    name: '华北地区',
    // "value": 2461116.88
  },
  {
    parent: 'TOP_0',
    id: 'AREA_4',
    name: '东北地区',
    value: 0,
  },
  {
    parent: 'TOP_0',
    id: 'AREA_5',
    name: '西北地区',
    value: 0,
  },
  {
    parent: 'TOP_0',
    id: 'AREA_6',
    name: '华东地区',
    value: 0,
  },
  {
    parent: 'TOP_0',
    id: 'AREA_7',
    name: '华中地区',
    value: 0,
  },
  {
    parent: 'TOP_0',
    id: 'AREA_8',
    name: '华南地区',
    value: 0,
  },
  {
    parent: 'TOP_0',
    id: 'AREA_9',
    name: '西南地区',
    value: 0,
  },

  {
    parent: 'AREA_3',
    id: 'FIRM_10',
    name: 'cs事务所(测试专用)',
    // "value": 1590126.88
  },
  {
    parent: 'AREA_3',
    id: 'FIRM_11',
    name: '华北事务所',
    // "value": 203376
  },
  {
    parent: 'AREA_3',
    id: 'FIRM_17',
    name: '三菱事务所',
    value: 0,
  },
  {
    parent: 'FIRM_10',
    id: 'DEALER_11',
    name: '珠海暖暖科技有限公司',
    value: 1590126.88,
  },
  {
    parent: 'FIRM_10',
    id: 'DEALER_23',
    name: '华商供暖',
    value: 539763,
  },
  {
    parent: 'FIRM_11',
    id: 'DEALER_21',
    name: '信息港经销商',
    value: 203376,
  },
  {
    parent: 'FIRM_11',
    id: 'DEALER_25',
    name: '天猫旗舰店',
    value: 897675,
  },
]
export default {
  name: 'sales_accounted_for',

  components: {
    SunburstStatis,
  },

  data() {
    return {
      charts: {
        chart: {
          height: '500px',
        },

        title: {
          text: '大区事务所经销商销售占比',
        },
        series: [
          {
            type: 'sunburst',
            data: [],
            allowDrillToNode: true,
            cursor: 'pointer',
            dataLabels: {
              formatter: function () {
                let shape = this.point.node.shapeArgs
                let innerArcFraction = (shape.end - shape.start) / (2 * Math.PI)
                let perimeter = 2 * Math.PI * shape.innerR

                let innerArcPixels = innerArcFraction * perimeter

                if (innerArcPixels > 16) {
                  return this.point.name
                }
              },
            },
            levels: [
              {
                level: 2,
                colorByPoint: true,
                dataLabels: {
                  rotationMode: 'parallel',
                },
              },
              {
                level: 3,
                colorVariation: {
                  key: 'brightness',
                  to: -0.5,
                },
              },
              {
                level: 4,
                colorVariation: {
                  key: 'brightness',
                  to: 0.5,
                },
              },
            ],
          },
        ],
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{point.name}</b>的占比是：<b>{point.value}</b>',
        },
        credits: {
          enabled: false,
        },
      },

      dataList: [],
    }
  },

  computed: {},

  created() {
    this.getCharts()
  },

  methods: {
    getCharts() {
      this.axios.get(`/api/base/system/report/getAreaCollectionStatistics`).then((res) => {
        console.log(res.body)
        this.$set(this.charts.series[0], 'data', res.body)
        this.$refs.SunburstStatis.getCharts()
      })
      // this.$set(this.charts.series[0], 'data', data)
      // this.$refs.SunburstStatis.getCharts()
    },
  },
}
</script>

<style lang='scss' scoped>
</style>